import { Component } from 'react';
import * as language from '../constants/languages';
import Snackbar from '@mui/material/Snackbar';
import '../styles/FeaturedProducts.css'
import Plus from '../assets/svg/plus.svg'
import Minus from '../assets/svg/minus.svg'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import '../styles/FeaturedProducts.css'
export default class FeaturedProductListComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSlideIndex: 0,
            data: [],
            orientation: '',
            snackopen: false,
            message: ''
        };
    }
    async cartQty() {

        try {

            const cart = localStorage.getItem('cartItems')
            if (cart) {
                this.cartItems = JSON.parse(cart);
                for (let i of this.props.data) {
                    const elementsIndex = this.cartItems.customerCartItems.findIndex(element => element.product.productId == i.product.productId);
                    if (elementsIndex != -1) {
                        i.product.cartQty = this.cartItems.customerCartItems[elementsIndex].qty
                    }
                    else {
                        i.product.cartQty = 0
                    }
                }

                for (let i = 0; i < this.props.data.length; i++) {
                    if (this.props.data[i].product.cartQty > 0) {
                        this.setState({
                            ['quantity_' + (this.props.data[i].product.productId)]: this.props.data[i].product.cartQty,
                            ['value_' + (this.props.data[i].product.productId)]: null,
                        })
                    }
                    else {
                        this.setState({
                            ['quantity_' + (this.props.data[i].product.productId)]: 0,
                            ['value_' + (this.props.data[i].product.productId)]: null,
                        })
                        this.props.data[i].product.cartQty = 0
                    }
                }
            }
            else {

                for (let i = 0; i < this.props.data.length; i++) {
                    this.setState({
                        ['quantity_' + (this.props.data[i].product.productId)]: 0,
                        ['value_' + (this.props.data[i].product.productId)]: null,
                    })

                    this.props.data[i].product.cartQty = 0

                    // }
                }
            }
            this.setState({
                data: this.props.data
            })
        }
        catch (e) { console.log(e, 'error') };
    }


    action = (
        <>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => this.setState({
                    snackopen: false
                })}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    componentDidMount() {
        this.cartQty()
        this.getCompanyInfo();
        window.addEventListener('productQtyChange', this.qtyChangeEvent)

    }
    componentWillUnmount() {
        window.removeEventListener('productQtyChange', this.qtyChangeEvent);
    }

    qtyChangeEvent = (data) => {
        if (data.token != 0) {
            this.cartQty();
        }
    }

    //get sotre name
    async getCompanyInfo() {
        const value = localStorage.getItem('companyInfo')

        if (value === null || value === undefined) {
            this.companyInfo = null;
            this.setState({
                currencySymbol: ""
            })
        } else {
            this.companyInfo = JSON.parse(value);
            this.setState({
                currencySymbol: this.companyInfo.currencySymbol
            })
        }


    }
    async addToCart(id, productData, index, e) {
        e.stopPropagation()
        if (productData.incrementValue == null) {
            this.incrementValue = 1
        } else {
            this.incrementValue = productData.incrementValue
        }
        this.branchId = localStorage.getItem('storeId');
        if (productData.isStockAvailable == true) {
            this.cartItemsJson = localStorage.getItem('cartItems');
            if (!this.cartItemsJson) {
                this.cartItems = {
                    customerCartItems: [
                    ],
                    branchId: parseInt(this.branchId),
                    companyId: language.companyidValue
                }
            } else {
                this.cartItems = JSON.parse(this.cartItemsJson);
            }

            const elementsIndex = this.cartItems.customerCartItems.findIndex(element => element.product.productId == productData.productId)
            if (this.branchId == this.cartItems.branchId) {
                var requiredQty = elementsIndex == -1 ? this.incrementValue : (this.cartItems.customerCartItems[elementsIndex].qty + this.incrementValue);

                if (productData.allowNegativeStock == true || requiredQty <= (productData.stock ?? 0)) {
                    if (elementsIndex == -1) {
                        this.cartItems.customerCartItems = [
                            ...this.cartItems.customerCartItems,
                            {
                                product: productData,
                                qty: this.incrementValue,
                                variantId: null
                            }
                        ]
                    } else {
                        this.cartItems.customerCartItems[elementsIndex] = {
                            product: this.cartItems.customerCartItems[elementsIndex].product,
                            qty: this.cartItems.customerCartItems[elementsIndex].qty + this.incrementValue,
                            variantId: this.cartItems.customerCartItems[elementsIndex].variantId
                        }
                    }
                    this.setState({
                        ['quantity_' + id]: this.state.data[index].cartQty + this.incrementValue,
                        message: language.itemAddedTocart,
                        snackopen: true
                    })
                    localStorage.setItem('cartItems', JSON.stringify(this.cartItems))
                    const event = new CustomEvent('productQtyChange');
                    window.dispatchEvent(event)
                    this.cartQty()
                } else {
                    this.setState({
                        message: language.cartAddRemove,
                        snackopen: true
                    })
                }

            } else {
                this.setState({
                    message: language.anotherBranch,
                    snackopen: true
                })
            }


        } else {
            this.setState({
                message: language.cartAddRemove,
                snackopen: true
            })
        }

    }
    // quantity select
    async quantityPressed(itemIndex, valueAction, productPrice, productId, productData, productListIndex, e) {
        e.stopPropagation()
        if (productData.incrementValue == null) {
            this.incrementValue = 1
        } else {
            this.incrementValue = productData.incrementValue
        }
        if (this.state['value_' + itemIndex] == null) {

            if (valueAction === 'increment') {
                this.cartAddRemove(productId, 1, itemIndex, productData) //add qny to cart
            } else {
                if (this.state['quantity_' + itemIndex] != this.incrementValue) {

                    this.cartAddRemove(productId, 0, itemIndex, productData) //reduce qny to cart
                } else {
                    this.removeItem(productId, productData, productListIndex);
                }
            }
        }

    }
    // remove the product
    async removeItem(id, productData, index) {
        let cart = localStorage.getItem('cartItems');
        let cartItems = JSON.parse(cart);
        if (productData.incrementValue == null) {
            this.incrementValue = 1
        } else {
            this.incrementValue = productData.incrementValue
        }
        const elementsIndex = cartItems.customerCartItems.findIndex(element => element.product.productId == id);
        cartItems.customerCartItems.splice(elementsIndex, 1);
        localStorage.setItem('cartItems', JSON.stringify(cartItems))

        if (cartItems.customerCartItems.length == 0) {
            localStorage.removeItem('cartItems')
        }
        this.cartQty();
        this.setState({
            message: language.removedFromCart,
            snackopen: true
        })

        const event = new CustomEvent('productQtyChange');;
        window.dispatchEvent(event)

    }

    // add/remove cart
    async cartAddRemove(id, action, itemIndex, productData) {
        this.branchId = localStorage.getItem('storeId');
        this.cartItemsJson = localStorage.getItem('cartItems');

        if (!this.cartItemsJson) {
            this.cartItems = {
                customerCartItems: [
                ],
                branchId: parseInt(this.branchId),
                companyId: language.companyidValue
            }
        } else {
            this.cartItems = JSON.parse(this.cartItemsJson);
        }
        if (productData.incrementValue == null) {
            this.incrementValue = 1
        } else {
            this.incrementValue = productData.incrementValue
        }
        if (action == 1) { //add to cart
            if (productData.isStockAvailable == true) {


                const elementsIndex = this.cartItems.customerCartItems.findIndex(element => element.product.productId == productData.productId)
                if (this.branchId == this.cartItems.branchId) {
                    var requiredQty = elementsIndex == -1 ? this.incrementValue : (this.cartItems.customerCartItems[elementsIndex].qty + this.incrementValue);
                    productData.allowNegativeStock = true
                    if (productData.allowNegativeStock == true || requiredQty <= (productData.stock ?? 0)) {
                        if (elementsIndex == -1) {
                            this.cartItems.customerCartItems = [
                                ...this.cartItems.customerCartItems,
                                {
                                    product: productData,
                                    qty: this.incrementValue,
                                    variantId: null
                                }
                            ]
                        } else {
                            this.cartItems.customerCartItems[elementsIndex] = {
                                product: this.cartItems.customerCartItems[elementsIndex].product,
                                qty: this.cartItems.customerCartItems[elementsIndex].qty + this.incrementValue,
                                variantId: this.cartItems.customerCartItems[elementsIndex].variantId
                            }
                        }

                        // await Asyncstorage.setStringItem('cartItems', JSON.stringify(cartItems));
                        localStorage.setItem('cartItems', JSON.stringify(this.cartItems))
                        const event = new CustomEvent('productQtyChange', { token: 0 });;
                        window.dispatchEvent(event)

                        this.setState({
                            ['quantity_' + itemIndex]: this.state['quantity_' + itemIndex] + this.incrementValue,
                        })
                    } else {
                        this.setState({
                            message: language.cartAddRemove,
                            snackopen: true
                        })
                    }

                } else {
                    this.setState({
                        message: language.anotherBranch,
                        snackopen: true
                    })
                }


            } else {
                this.setState({
                    message: language.cartAddRemove,
                    snackopen: true
                })
            }


        } else { //reduce from cart
            if (this.branchId == this.cartItems.branchId) {
                const elementsIndex = this.cartItems.customerCartItems.findIndex(element => element.product.productId == id);
                if (elementsIndex > -1) {
                    var requiredQty = elementsIndex == -1 ? this.incrementValue : (this.cartItems.customerCartItems[elementsIndex].qty - this.incrementValue);
                    this.cartItems.customerCartItems[elementsIndex] = {
                        product: this.cartItems.customerCartItems[elementsIndex].product,
                        qty: (requiredQty < 0 ? 0 : requiredQty),
                        variantId: this.cartItems.customerCartItems[elementsIndex].variantId,
                    }
                    // await Asyncstorage.setStringItem('cartItems', JSON.stringify(cartItems));
                    localStorage.setItem('cartItems', JSON.stringify(this.cartItems))
                    const event = new CustomEvent('productQtyChange', { token: 0 });;
                    window.dispatchEvent(event)

                    // Toast.show(language.itemReducedFromCart);
                    this.setState({
                        ['quantity_' + itemIndex]: this.state['quantity_' + itemIndex] - this.incrementValue,
                    })

                }

            } else {
                this.setState({
                    message: language.anotherBranch,
                    snackopen: true
                })
            }

        }
    }
    onSnackClose = () => {
        this.setState({
            snackopen: false
        })
    }
    gotoDetails = (item) => {
        this.props.navigate(`/Productdetails/${item.productId}`)
    }

    render() {
        return (

            <div className='grid-container'>
                <div style={{ display: 'flex', overflowX: 'scroll', overflowY: 'hidden' }}>
                    {
                        this.state.data?.map((item, i) => (
                            <div key={i} className='grid-item-product' onClick={()=>this.gotoDetails(item.product)} >
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', marginBottom:'1rem' }}>
                                    <img src={item.product.tblProductImages ? item.product.tblProductImages[0].image : ''} className='image-featured' alt='Product Image'/>
                                </div>
                                {
                                    item.product.oRate == null ?
                                        <div style={{ position: 'absolute', left: 0, top: 15 }}>
                                            {
                                                item.product.sRate < (item.product.mrp == null ? item.product.sRate : item.product.mrp) ?
                                                    <div style={{ position: 'absolute', left: 0 }}>
                                                        <p className='offerTag'>{item.product.savedPercentage}% OFF</p>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        :
                                        <div style={{ position: 'absolute', left: 0, top: 15 }}>
                                            {
                                                item.product.oRate < (item.product.mrp == null ? item.product.sRate : item.product.mrp) ?
                                                    <div style={{ position: 'absolute', left: 0 }}>
                                                        <p className='offerTag'>{item.product.savedPercentage}% OFF</p>
                                                    </div>
                                                    :
                                                    null

                                            }
                                        </div>
                                }
                                <div className='stockWrapper'>
                                    <p style={{ fontFamily: 'Poppins-Regular' }}>{item.product.productName}</p>
                                    {item.product.regionalLanguage == (null || "") ? null : <p style={{ fontFamily: 'Poppins-Regular', fontSize: 12, color: '#424242' }}>{item.product.regionalLanguage}</p>}

                                    {
                                        item.product.isStockAvailable == false ?
                                            <p style={{ fontFamily: 'BeVietnamPro-Regular', fontSize: 12, color: language.red }}>{language.outStockLabel}</p>
                                            :
                                            <p style={{ fontFamily: 'BeVietnamPro-Regular', fontSize: 12, color: language.green }}>{language.inStockLabel}</p>
                                    }
                                </div>
                                <div className='productDetailsWrapper'>

                                    {

                                        item.product.oRate == null ?
                                            <div>
                                                {
                                                    item.product.sRate < (item.product.mrp == null ? item.product.sRate : item.product.mrp) ?
                                                        <div style={{ alignItems: 'center' }}>
                                                            <p style={{ fontFamily: 'Montserrat-Regular', textDecorationLine: 'line-through', marginRight: 5, fontSize: 14 }}>
                                                                {this.state.currencySymbol} {item.product.mrp * item.product.incrementValue}
                                                            </p>
                                                            <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000' }}>
                                                                {this.state.currencySymbol} {item.product.sRate * item.product.incrementValue}
                                                            </p>

                                                        </div>
                                                        :

                                                        <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000' }}>
                                                            {this.state.currencySymbol} {item.product.sRate * item.product.incrementValue}
                                                        </p>
                                                }
                                            </div>
                                            :
                                            <div>
                                                {
                                                    item.product.oRate < (item.product.mrp == null ? item.product.sRate : item.product.mrp) ?
                                                        <div style={{ alignItems: 'center' }}>
                                                            <p style={{ fontFamily: 'Montserrat-Regular', textDecorationLine: 'line-through', marginRight: 5, fontSize: 14 }}>
                                                                {this.state.currencySymbol} {(item.product.mrp == null ? item.product.sRate : item.product.mrp) * item.product.incrementValue}
                                                            </p>
                                                            <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000' }}>
                                                                {this.state.currencySymbol} {item.product.oRate * item.product.incrementValue}
                                                            </p>

                                                        </div>
                                                        :
                                                        <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000' }}>
                                                            {this.state.currencySymbol} {item.product.sRate * item.product.incrementValue}
                                                        </p>

                                                }
                                            </div>
                                    }
                                    {/* {
                                        language.type == 0 ?

                                            <div style={{ flex: 1, justifyContent: 'center',cursor:'pointer' }}>
                                                {
                                                    item.product.cartQty > 0 ?
                                                        <div style={{ alignItems: 'center', marginTop: 10, justifyContent: 'center' }}>
                                                            {
                                                                item.product.isStockAvailable == false ?
                                                                    null :
                                                                    <div style={{ padding: 10 }}>
                                                                        <div className='quantityWrapperFeaturedProducts'>

                                                                            <div onClick={(e) => this.quantityPressed(item.product.productId, 'decrement', item.product.sRate, item.product.productId, item.product, (i + 1), e)}>
                                                                                <img src={Minus} size={14} />
                                                                            </div>

                                                                            <div style={{ marginHorizontal: 10, alignItems: 'center', }}>

                                                                                {
                                                                                    item.product.unit != '-- None --' ?
                                                                                        <p style={{ fontFamily: 'Poppins-Regular', margin: 0, fontSize: 12 }}>{this.state['quantity_' + (item.product.productId)]} {item.product.unit}</p>
                                                                                        :
                                                                                        <p style={{ fontFamily: 'Poppins-Regular', margin: 0, fontSize: 12 }}>{this.state['quantity_' + (item.product.productId)]}</p>
                                                                                }
                                                                            </div>


                                                                            <div onClick={(e) => this.quantityPressed(item.product.productId, 'increment', item.product.sRate, item.product.productId, item.product, (i + 1), e)}>
                                                                                <img src={Plus} size={14} />

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                            }
                                                        </div>
                                                        :

                                                        <div style={{ padding: 10 }}>
                                                            <div className='cartWrapper' onClick={(e) => this.addToCart(item.productId, item.product, i, e)}>
                                                                <p style={{ fontFamily: 'Poppins-Regular', fontSize: 12, margin: 0 }} > {language.addToCartLabel} </p>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            :
                                            null
                                    } */}

                                </div>
                            </div>
                        ))
                    }
                </div>
                <Snackbar
                    open={this.state.snackopen}
                    autoHideDuration={2000}
                    onClose={this.onSnackClose}
                    message={this.state.message}
                    action={this.action}
                />
            </div>
        )
    }
}